<script>
/**
 * Novo componente para modais.
 * Um kore-modal v2.
 * Permite maior personalização do dialog além do uso sincrono ou assíncrono dele.
 */
export default {
  // components: {},
  // directives: {},
  // filters: {},
  // extends: {},
  // mixins: {},
  model: {
    event: "update:opened",
    prop: "opened",
  },
  props: {
    appendTitleIcon: String,
    hideButtons: Boolean,
    hideCloseIcon: Boolean,
    hideHeader: Boolean,
    noPadding: Boolean,
    opened: Boolean,
    width: String,
    title: {
      type: String,
      default: "Aviso",
    },
  },
  data: function () {
    return {
      innerOpened: this.opened,
      resolve: () => {},
      reject: () => {},
    };
  },
  // computed: {},
  watch: {
    opened(nextValue) {
      this.innerOpened = nextValue;
    },
  },
  // created() {},
  methods: {
    close(response = null) {
      this.innerOpened = false;
      this.$emit('update:opened', false);
      this.$emit('close', response);
      this.resolve(response);
    },
    asyncOpen() {
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
        this.innerOpened = true;
      this.$emit('update:opened', true);
      });
    },
    open: function () {
      this.innerOpened = true;
      this.$emit('update:opened', true);
    },
  },
};
</script>

<template>
  <!-- O width="auto " com o espaço extra é de propóstio e não sei por que só funciona assim -->
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    scrollable
    :value="innerOpened"
    :width="width ? width : 'auto '"
    @input="$emit('update:opened', $event)"
  >
    <v-card :class="{ 'no-padding': noPadding }">
      <template v-if="!hideHeader">
        <v-card-title>
          <slot name="header" v-bind="{ appendTitleIcon, title, hideCloseIcon, close }">
            <v-icon v-if="appendTitleIcon" x-large>
              {{ appendTitleIcon }}
            </v-icon>
            <span id="dialog-title">
              {{ title }}
            </span>
            <v-btn
              v-if="!hideCloseIcon"
              class="header-close-btn"
              icon
              @click="close(null)"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </slot>
        </v-card-title>
        <v-divider></v-divider>
      </template>
      <v-card-text :class="{ 'no-padding': noPadding }">
        <slot></slot>
      </v-card-text>
      <template v-if="!hideButtons">
        <v-divider></v-divider>
        <v-card-actions>
          <slot name="buttons" v-bind="{ close }">
            <v-spacer />
            <v-btn
              class="px-5 ml-3"
              color="primary"
              dark
              depressed
              @click="close(null)"
            >
              OK
            </v-btn>
            <v-spacer />
          </slot>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-card__title {
  display: block;
  padding-bottom: 4px;

  .v-icon {
    margin-right: 12px;
  }

  .dialog-title {
    word-break: keep-all;
  }
}

.header-close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}

.v-card__text.v-card__text {
  padding-bottom: 0;

  &.no-padding {
    padding: 0;
  }
}

.v-card__actions {
  padding: 0 24px 12px;
}

.no-padding {
  .v-card__title + .v-divider {
    margin-bottom: 0;
  }

  .v-card__text + .v-divider {
    margin-top: 0;
  }

  .v-card__text {
    padding: 0;
  }
}
</style>
